<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "CancelSubscription",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "4.4   Cancelling your Subscription",
          description: [
            {
              text: "If you would like to cancel your subscription, you can go to the Account tab and tap on Subscription. ",
              imgType: false,
              img: "400.Account – empty – 1.png",
            },
            {
              text: "You shall be able to view  your current subscription plan and billing details",
              imgType: false,
              img: "695.Subscription-empty.png",
            },
            {
              text: "Tap on “Cancel renewal” on the current plan row.",
              imgType: false,
            },
            {
              text: "Select your reasons for cancelling the renewal. Tap on “Cancel premium” at the bottom of the screen to confirm the cancellation. During your paid up period, you will still be able to use your Kloser account, and you will only lose access once it ends.\n",
              imgType: false,
            },
          ],
        },
      ],
    };
  },
};
</script>
